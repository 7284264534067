import React from "react";

interface Props {
  translation?: any
}

const FooterPolicies = ({ translation  } : Props) => {
    const showAdobeCookiesPolicy = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (undefined !== typeof adobePrivacy) {
            event.preventDefault();
            adobePrivacy.showConsentPopup();
        }        
    }
    return (
    <div className="footer__policies">
        { translation.general.privacy_policy_link !== '' && 
            <a href={ translation.general.privacy_policy_link } target="_blank" className="footer__privacy">{ translation.general.privacy_policy }</a>
        }
        { translation.general.cookies_policy_link !== '' && translation.general.privacy_policy_link !== '' &&
                <span>/</span>
        }
        { translation.general.cookies_policy_link !== '' && 
        <a href={ translation.general.cookies_policy_link } target="_blank" className="footer__privacy footer__privacy--cookies">{ translation.general.cookies_policy }</a>
        }
        { translation.general.cookies_settings !== '' &&
                <span>/</span>
        }
        { translation.general.cookies_settings !== '' && 
            <a href="" onClick={ showAdobeCookiesPolicy } target="_blank" className="footer__privacy footer__privacy--cookies">{ translation.general.cookies_settings }</a>
        }
    </div>
);
    }


export default FooterPolicies
