import { Link } from "gatsby"
import React from "react"

interface Props {
  className?: string
  children?: any
}
const Footer = ({ className, children } : Props) => (
  <footer className={`footer ${ className ? className : '' }`}>
    <div className="container">
      <div className="footer__content">
          { children }
        </div>
    </div>
  </footer>
)


export default Footer
