import React from 'react';
import TransitionLink from './transitionLink';
import ShareButton from './share-button';
import CQLogo from './cqlogo';


interface Props {
    socialFinalImageWechat?: string
    socialFinalImageWeibo?: string
    socialData: any
    socialLinks: any
    lang: string
    translation: any
    route: string
    className?: string
    about: boolean
    share: boolean
    handleClickAbout: () => void
}

const Header = ({ className, socialData=[], socialLinks=[], about, share, handleClickAbout, translation, socialFinalImageWeibo = '', socialFinalImageWechat = '', lang='', route = '' }:Props) => {
    return (
        <header className={`header ${ className ? className : '' }`}>
            <div className="container">
                <div className="header__content">
                    { about ? <button className="link" onClick={ ()=> handleClickAbout() }><span>{ translation ? translation.general.about : 'About' } </span></button> : <div></div> }                        
                    { share ? <ShareButton {...socialData} weiboImageLink={socialFinalImageWeibo} wechatImageLink={socialFinalImageWechat} {...socialLinks} translation={ translation } className="footer__link-right" /> : <TransitionLink className="cq-logo header__link-right" to={ route }><CQLogo lang={ lang }/></TransitionLink> }
                </div>
            </div>
        </header>
    )
}

export default Header
