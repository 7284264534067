import React, { useState } from "react"
import SocialShareList from './social-share-list';
import IconClose from '../images/cross.inline.svg';

interface Props {
    title?: string
    description?: string
    translation: any
    className?:string
    twitter?: boolean
    facebook? : boolean
    linkedin?: boolean
    weibo?: boolean
    weiboImageLink?:string
    wechat?: boolean
    wechatImageLink?:string
}

const ShareButton = ({ title, description, className, translation, twitter = false, facebook = false, linkedin = false, weibo = false, weiboImageLink = '', wechat = false, wechatImageLink = '' }: Props) => {
    const [ show, setShow ] = useState(false);
    const handleClick = () => {
        setShow(!show);
    }
    return (
        <div className={ `share-button ${ show ? 'share-button--show' : ''} ${ className ? className : ''}`}>
            <button aria-label="Open Share List Box - Displays a list of social media share options" onClick={ handleClick } className="link"><span>{ translation ? translation.general.share : 'Share' }</span></button>
            <div className="share-button__socials">
                <button onClick={handleClick} className="close" aria-label="Close Share List Box"><IconClose/></button>
                <SocialShareList title={ title } description={ description } twitter={ twitter } facebook={ facebook } linkedin={ linkedin } weibo={ weibo } weiboImageLink={weiboImageLink} wechat={ wechat } wechatImageLink={wechatImageLink}/>
            </div>
        </div>
    )
}


export default ShareButton
