/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from 'gatsby';
import { isFirefox, isTouch, isIE11, isAndroid, isIphone, isMobile } from '../utils/utilities';
import LocationRedirect from '../utils/locationRedirect';
import Popup from './popup';
import 'focus-visible';
import AboutFigures1 from '../images/about/about-figures-1.png';
import AboutFigures2 from '../images/about/about-figures-2.png';
import AboutFigures3 from '../images/about/about-figures-3.png';
import AboutFigures4 from '../images/about/about-figures-4.png';
import AboutFigures5 from '../images/about/about-figures-5.png';
import Header from "./header"
import Footer from './footer';
import "../styles/styles.scss"
import { Helmet } from "react-helmet";

interface Props {
  translation?: any
  lang?: string
  route: string
  title?:string
  className?: string
  children?: any
  about?: boolean
  share?: boolean
  slug?: string
  pathname: string
  footerContent?: any
  footerClassName?: string,
  socialLinks?: any
  socialMediaImageFacebook?: string,
  socialMediaImageLinkedin?: string,
  socialMediaImageTwitter?: string,
  socialMediaImageWeibo?: string,
  socialMediaImageWechat?: string,
  socialMediaTitle?: string,
  socialMediaDescription?:string
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: siteUrl        
      }
    }
  }
`

const Layout = ({ className, socialLinks = {}, pathname='',route = '/', lang = '',  slug = "", share = true, about = true, title, children, footerContent, socialMediaTitle, socialMediaImageFacebook,socialMediaImageLinkedin,socialMediaImageTwitter, socialMediaImageWeibo, socialMediaImageWechat, socialMediaDescription,footerClassName, translation }: Props) => {
    const { site } = useStaticQuery(query);
    const { siteUrl } = site.siteMetadata;
    const [ showAbout, setShowAbout ] = useState<boolean>(false);
    const handleClose = () => {
        setShowAbout(false);
    };
    const handleClickAbout = () => {
        setShowAbout(true);
    }

    useEffect(() => {
        const redirectByIP = new LocationRedirect();
        redirectByIP.init();
        if (isMobile) {
            document.documentElement.classList.add('mobile');
        }
        const reduceHeight = !isFirefox && isAndroid && !isIphone;
        if (reduceHeight) {
            document.documentElement.classList.add('reduced-height');
        }
    },[]);

    useEffect(() => {
        if (isTouch) {
        document.documentElement.classList.add('is-touch');
        }
    },[isTouch]);
    
    useEffect(() => {
        if (isIE11) {
            document.documentElement.classList.add('ie11');
        }
        document.documentElement.classList.add('ready');
    },[]);

    const socialLinksAvailable = Object.keys(socialLinks).map((item) => {
        return item;
    });

    let regionLang = lang;

    if (regionLang === 'eu') {
        regionLang = 'en';
    }

    const socialFinalTitle = socialMediaTitle ? socialMediaTitle : translation && translation.general ? translation.general.cq : 'CQ';
    const socialFinalImageFacebook =  socialMediaImageFacebook ? socialMediaImageFacebook : `/socials/${regionLang}/adobecq-facebook.png`;
    const socialFinalImageLinkedin =  socialMediaImageLinkedin ? socialMediaImageLinkedin : `/socials/${regionLang}/adobecq-linkedin.png`;
    const socialFinalImageWeibo = socialMediaImageWeibo ? socialMediaImageWeibo : `${siteUrl}/socials/${regionLang}/adobecq-weibo.png`;
    const socialFinalImageWechat = socialMediaImageWechat ? socialMediaImageWechat : `${siteUrl}/socials/${regionLang}/adobecq-wechat.png`;
    const socialFinalDescription = socialMediaDescription ? socialMediaDescription : translation && translation.general ? translation.general.discover_your_creative_potential_to_lead : 'Discover your creative potential to lead' ;
    const finalTitle = title || `${ translation ? translation.general.adobe_cq : 'Adobe CQ' } | ${ translation  && translation.general ? translation.general.discover_your_creative_potential_to_lead : 'Discover your creative potential to lead' }`;
    const socialData = { title: socialFinalTitle, description:socialFinalDescription, image: socialFinalImageFacebook };
    return (
        <>
            <Helmet>
                <title>{finalTitle}</title>
                { lang !=='cn' && <link rel="stylesheet" href="https://use.typekit.net/iya7twt.css"/>}                
                <meta property="og:title" content={socialFinalTitle} />
                <meta property="og:type" content="article" />
                <meta property="og:locale" content={ lang === '' ? 'en' : lang } />
                <meta property="og:url" content={`${siteUrl}${pathname}`} />
                <meta name="description" property="og:description" content={socialFinalDescription} />
                {/* Image for Linkedin */}
                {socialLinksAvailable.includes('linkedin') && <meta name="image" property="og:image" content={ `${siteUrl}${socialFinalImageLinkedin}` } />}
                {socialLinksAvailable.includes('linkedin') && <meta property="og:image:width" content="1200" />}
                {socialLinksAvailable.includes('linkedin') && <meta property="og:image:height" content="628" />}
                {/* Image for Facebook */}
                {socialLinksAvailable.includes('facebook') && <meta property="og:image" content={ `${siteUrl}${socialFinalImageFacebook}` }/> }
                {socialLinksAvailable.includes('facebook') && <meta property="og:image:width" content="1200" />}
                {socialLinksAvailable.includes('facebook') && <meta property="og:image:height" content="628" />}
                {/* Image for Twitter */}
                {socialLinksAvailable.includes('twitter') && <meta name="twitter:description" content={socialFinalDescription} />}
                {socialLinksAvailable.includes('twitter') &&<meta name="twitter:card" content="summary_large_image" />}
                {socialLinksAvailable.includes('twitter') &&<meta name="twitter:image:alt" content={ socialFinalTitle } />}
                {socialLinksAvailable.includes('twitter') &&<meta name="twitter:creator" content="@adobe" />}
                {socialLinksAvailable.includes('twitter') &&<meta name="twitter:image" content={ `${siteUrl}${socialFinalImageFacebook}` } />}
            </Helmet>
            <main className={ showAbout ? `blurred lang-${lang}`: `lang-${lang}`}>
                <Header socialData={socialData} socialFinalImageWeibo={socialFinalImageWeibo} socialFinalImageWechat={socialFinalImageWechat} lang={lang} socialLinks={ socialLinks } route={route} translation={ translation } share={ share } about={ about } className={className} handleClickAbout={ handleClickAbout }/>          
                    <div className="page-content">
                        { children }
                    </div>
                <Footer className={footerClassName}>
                    { typeof footerContent === 'function' && footerContent() }  
                </Footer>
            </main>
            { translation && 
            <Popup show={showAbout} onClose={ handleClose }>
                <div className="about">
                    <h2 className="h1">{ translation.general.about_adobe_cq }</h2>
                    <div className="about__content">
                        <div className="about__content-left">
                            <img src={ AboutFigures1 } alt="Figures Green Ball and Purple"/>
                            <div dangerouslySetInnerHTML={{__html: translation.about_us_popup.paragraph1 }}></div>
                        </div>
                        <div className="about__content-right">
                            <img src={ AboutFigures2 } alt="Figures White Pyramid and Blue tube"/>
                            <div dangerouslySetInnerHTML={{__html: translation.about_us_popup.paragraph2 }}></div>
                        </div>
                        <div className="about__content-left">
                            <img src={ AboutFigures3 } alt="Figures Red Ball and Blue Cube"/>
                            <div dangerouslySetInnerHTML={{__html: translation.about_us_popup.paragraph3 }}></div>
                        </div>
                        <div className="about__content-right">
                            <img src={ AboutFigures4 } alt="Figures Green Pyramid and Blue Half ball"/>
                            <div dangerouslySetInnerHTML={{__html: translation.about_us_popup.paragraph4 }}></div>
                        </div>
                        <div className="about__content-left">
                            <img src={ AboutFigures5 } alt="Figures Red Disc and Purple Ball"/>
                            <div>
                                <div dangerouslySetInnerHTML={{__html: translation.about_us_popup.paragraph5 }}></div>
                                <div dangerouslySetInnerHTML={{__html: translation.about_us_popup.paragraph6 }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
            }
        </>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
