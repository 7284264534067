import React, { useState, useEffect} from "react"
import LogoDefault from '../images/cq-logo-shadow.inline.svg';
import LogoCN from '../images/cq-logo-cn.inline.svg';

interface Props {
    lang: string
}
const CQLogo = ({ lang } : Props) => {
    const [logo, setLogo] = useState('');
    useEffect(() => {
        setLogo(lang);
    }, [lang, setLogo]);
    return (<>
        { logo === 'cn' && <LogoCN/>}
        { logo !== 'cn' && <LogoDefault/>}
        </>)
}

export default CQLogo;
