import React, { useState, useEffect } from "react"
import FacebookIcon from '../images/facebook.inline.svg';
import TwitterIcon from '../images/twitter.inline.svg';
import LinkedinIcon from '../images/linkedin.inline.svg';
import WeiboIcon from '../images/weibo.inline.svg';
import WechatIcon from '../images/wechat.inline.svg';
import { browserPopup } from '../utils/utilities';

interface Props {
    title?: string
    description?: string
    twitter: boolean,
    facebook: boolean,
    linkedin: boolean,
    weibo: boolean,
    weiboImageLink?: string    
    wechat: boolean,
    wechatImageLink?: string    
}
const SocialShareList = ({ title, description, twitter = false, facebook = false, linkedin = false, weibo = false, weiboImageLink, wechat = false, wechatImageLink= '' }:Props) => {
    const [url, setURL] = useState("");
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const locattionHref: string = window.location.href;
            setURL(locattionHref);
        }
    });
    return (
        <div className="social-share-list">
            {linkedin &&
                <a aria-label="Share on Linkedin" onClick={() => browserPopup(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&source=adobecq`, 'linkedin')} className="social-share-list__link social-share-list__link--linkedin" href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&source=adobecq`} target="_blank">
                    <LinkedinIcon />
                </a>}
            {facebook &&
                <button onClick={() => browserPopup(`https://www.facebook.com/sharer/sharer.php?display=popup&u=${url}&hashtag=%23adobecq`, 'facebook')} aria-label="Share on Facebook" className="button--none social-share-list__link social-share-list__link--facebook" data-applink={`fb://sharer/sharer.php?u=${url}`} data-href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${url}&hashtag=%23adobecq`}>
                    <FacebookIcon />
                </button>
            }
            {twitter &&
                <a aria-label="Share on Twitter" onClick={() => browserPopup(`https://www.twitter.com/intent/tweet?url=${url}&hashtags=adobecq`, 'twitter')} className="button--none social-share-list__link social-share-list__link--twitter" href={`https://www.twitter.com/intent/tweet?url=${url}&hashtags=adobecq`} target="_blank">
                    <TwitterIcon />
                </a>
            }
            {weibo &&
                <a aria-label="分享" className="button--none social-share-list__link social-share-list__link--weibo" href={weiboImageLink} target="_blank">
                    <WeiboIcon />
                </a>
            }
            {wechat &&
                <a aria-label="分享" className="button--none social-share-list__link social-share-list__link--wechat" href={wechatImageLink} target="_blank">
                    <WechatIcon />
                </a>
            }
        </div>
    )
}


export default SocialShareList;
