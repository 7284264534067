class locationRedirect {
    constructor() {
        this.translationCountries = {
            "cn": ['CN'],
            "eu": ['GB','UK', 'BV','NO','SJ','IS','LI','AT','BE','BG','HR','CY','CZ','DK','EE','EW','AX','FI','SF','FR','CP','FX','DE','DD','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES', 'EA','IC','SE','CA']
        };
        
        this.providerIPAPICO = {
            url: 'https://ipapi.co/json'
        }
        this.providerGeolizr = {
            url: 'https://service.geolizr.com/ipapi/'
        }
        this.ipLocationSite = localStorage.getItem('ipLocationSite') || null;
        this.ipLocationSetTimes = localStorage.getItem('ipLocationSetTimes') || 0;
        this.manualLocation = localStorage.getItem('manualLocation') || null;
    }
    setIPLocation(countryCode) {
        let translationSite = 'au';
        Object.keys(this.translationCountries).forEach((key) => {
            if (this.translationCountries[key].indexOf(countryCode) > -1) {
                translationSite = key;
            }
        })
        localStorage.setItem('ipLocationSite', translationSite);
        this.ipLocationSite = translationSite;
        this.redirect(this.ipLocationSite);
    }
    checkIP() {
        let controller1 = new AbortController();
        let controller2 = new AbortController();
        try {
            fetch(this.providerIPAPICO.url, {
                signal: controller1.signal
            }).then((response) => {
                return response.json();                
            }).then((data) => {
                controller2.abort();
                this.setIPLocation(data['country_code']);
            });
        } catch(err) {
            if (err.name == 'AbortError') { // handle abort()
                
            } else {
                throw err;
            }
        }        
        try {
            fetch(this.providerGeolizr.url, {
                signal: controller2.signal
            }).then((response) => {
                return response.json();                
            }).then((data) => {
                controller1.abort();
                this.setIPLocation(data['countryCode']);
            });
        } catch(err) {
            if (err.name == 'AbortError') { 
                
            } else {
                throw err;
            }
        }
    }
    redirect(ipLocationSite) {
        this.ipLocationSetTimes = ( this.ipLocationSetTimes * 1 ) + 1;
        localStorage.setItem('ipLocationSetTimes', this.ipLocationSetTimes);
        let url = new URL(window.location.href);
        if (ipLocationSite !== 'au' && !window.location.href.includes(`/${ipLocationSite}`)) {
            url = new URL(`${window.location.protocol}//${window.location.host}/${ipLocationSite}${window.location.pathname}`);
            window.location.href = url;
        }
    }
    init() {
        if (this.manualLocation) {
            if (this.manualLocation !== window.theme.cartCurrency) {
                this.redirect(this.manualLocation);
            }
        } else {
            if (this.ipLocationSite) {
                this.redirect(this.ipLocationSite);
            } else {
                this.checkIP();
            }
        }
    }
};
export default locationRedirect;