import React from 'react';
import { navigate } from 'gatsby';

interface Props {
    to: string
    className?: string
    children: any
}
const TransitionLink = ({ to, className, children }:Props) => {
    const handleClick = (event:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        document.documentElement.classList.remove('in');
        setTimeout( () => {
            navigate(to);
        }, 600)
    }

    return (
        <a href={ to } onClick={ handleClick } className={ className }>{ children }</a>
    );
}

export default TransitionLink;