import React, { useEffect, useRef } from "react"
import CloseIcon from '../images/cross.inline.svg'
import SimpleBar from 'simplebar-react';

interface Props {
    show: boolean
    onClose: () => void
    children?: any
}
const Popup = ({ onClose,show, children } : Props) => {
    const popupRef = useRef(null);
    const handleClose = () => {
        if (popupRef.current) {
            popupRef.current.classList.remove('show');            
        }
        if (typeof onClose === 'function') {
            setTimeout(() => {
                onClose();
            }, 300);            
        }
    }
    useEffect(() => {
        if (popupRef.current && show) {            
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                popupRef.current.classList.add('show');            
                });
            });            
        }
    },[show, popupRef]);
    if (!show) {
        return null;
    }
    return (
        <div ref={popupRef} className="popup">
            <div onClick={ handleClose } className="popup__bg"></div>
            <div className="popup__header">
                <button onClick={ handleClose } className="button--none button--icon popup__close"><CloseIcon/></button>                
            </div>
            <div className="popup__box">
                <SimpleBar className="popup__content">
                    { children }
                </SimpleBar>
                <div className="popup__gradient"></div>
            </div>
        </div>
    )
    }

export default Popup
